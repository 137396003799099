import fetch from 'auth/FetchMasterInterceptor'
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
const userService = {}
userService.excelLink = function (data) {
  return fetch({
    url: '/excel-link',
    method: 'get',
    data:data
  })
}
userService.getUser = function (data) {
  return fetch({
    url: '/employee/list',
    method: 'post',
    data:data
  })
}
userService.addUser = function (data) {
  return fetch({
    url: '/employee/create',
    method: 'post',
    data:data
  })
}
userService.editUser = function (data) {
  return fetch({
    url: '/employee/update',
    method: 'post',
    data:data
  })
}
userService.assignUser = function (data) {
  return fetch({
    url: '/employee/assign',
    method: 'post',
    data:data
  })
}
userService.deleteUser = function (data) {
  return fetch({
    url: '/employee/delete',
    method: 'post',
    data:data
  })
}
userService.showUser = function (data) {
  return fetch({
    url: '/employee/show',
    method: 'post',
    data:data
  })
}
userService.addUserExperience = function (data) {
  return fetch({
    url: '/user-experience/create',
    method: 'post',
    data:data
  })
}
userService.ListUserEducation = function (data) {
  return fetch({
    url: '/education-details/list',
    method: 'post',
    data:data
  })
}
userService.DeleteUserEducation = function (data) {
  return fetch({
    url: '/education-details/delete',
    method: 'post',
    data:data
  })
}
userService.addUserEducation = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/education-details/create`, data, config);
}
userService.editUserEducation = function (data) {
  return fetch({
    url: '/education-details/update',
    method: 'post',
    data:data
  })
}
userService.ListUserExperience = function (data) {
  return fetch({
    url: '/user-experience/list',
    method: 'post',
    data:data
  })
}
userService.ShowUserExperience = function (data) {
  return fetch({
    url: '/user-experience/show',
    method: 'post',
    data:data
  })
}
userService.EditUserExperience = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-experience/update`, data, config);
}
userService.AddUserExperience = function (data) {
 const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-experience/create`, data, config);
}
userService.DeleteUserExperience = function (data) {
  return fetch({
    url: '/user-experience/delete',
    method: 'post',
    data:data
  })
 }
userService.editUserDocument = function (data) {
  return fetch({
    url: '/user-document/update',
    method: 'post',
    data:data
  })
}
userService.addUserDocument = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization":`Bearer ${jwtToken}`
    },
  };
  return axios
    .post(`${API_BASE_URL}/user-document/create`, data, config);
}
userService.deleteUserDocument = function (data) {
  return fetch({
    url: '/user-document/delete',
    method: 'post',
    data:data
  })
}
// userService.generateemployeesalary = function (data) {
//   return fetch({
//     url: '/type-rate/salary',
//     method: 'post',
//     data:data
//   })
// }
userService.employeesalarydetails = function (data) {
  return fetch({
    url: '/payslip/history',
    method: 'post',
    data:data
  })
}
userService.employeeTotalsalarydetails = function (data) {
  return fetch({
    url: '/type-rate/total/salary',
    method: 'post',
    data:data
  })
}
userService.showUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/list',
    method: 'post',
    data:data
  })
}
userService.deleteearningstates=function (data) {
  return fetch({
    url: '/type-rate/delete',
    method: 'post',
    data:data
  })
}
userService.editUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/update',
    method: 'post',
    data:data
  })
}
userService.addUsersalaryrate = function (data) {
  return fetch({
    url: '/type-rate/storeUpdate',
    method: 'post',
    data:data
  })
}
userService.getAttendees = function (data) {
  return fetch({
    url: '/health/list',
    method: 'post',
    data:data
  })
}
userService.showAttendees = function (data) {
  return fetch({
    url: '/health/show',
    method: 'post',
    data:data
  })
}
userService.updateAttendees = function (data) {
  return fetch({
    url: '/health/atten_dance',
    method: 'post',
    data:data
  })
}
userService.requestattendance = function (data) {
  return fetch({
    url: 'attendance/request/create',
    method: 'post',
    data:data
  })
}
userService.attendencerequestlist = function (data) {
  return fetch({
    url: 'attendance/request/list',
    method: 'post',
    data:data
  })
}
userService.attendenceApprovedReject = function (data) {
  return fetch({
    url: 'attendance/request/status',
    method: 'post',
    data:data
  })
}
userService.exportAttendees = function (data) {
  return fetch({
    url: '/health/excel/export',
    method: 'post',
    data:data
  })
}
userService.sendOtp = function (data) {
  return fetch({
    url: '/send-otp',
    method: 'post',
    data:data
  })
}
userService.verifyOtp = function (data) {
  return fetch({
    url: '/verify-otp',
    method: 'post',
    data:data
  })
}
userService.getFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/list',
    method: 'post',
    data:data
  })
}
userService.addFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/create',
    method: 'post',
    data:data
  })
}
userService.deleteFamilyDetails = function (data) {
  return fetch({
    url: '/family-details/delete',
    method: 'post',
    data:data
  })
}
userService.healthtrack = function (data) {
  return fetch({
    url: '/attendance',
    method: 'post',
    data:data
  })
}
userService.tlList = function (data) {
  return fetch({
    url: '/employee/team/list',
    method: 'post',
    data:data
  })
}
userService.DeletetlList = function (data) {
  return fetch({
    url: '/employee/deleteEmployeeAssignment',
    method: 'post',
    data:data
  })
}
userService.employeedataList = function (data) {
  return fetch({
    url: '/employee/search',
    method: 'post',
    data:data
  })
}
userService.managerdataList = function (data) {
  return fetch({
    url: '/manager/list',
    method: 'post',
    data:data
  })
}
userService.memberdataList = function (data) {
  return fetch({
    url: '/manager/getEmployeesUnderManager',
    method: 'post',
    data:data
  })
}
userService.teamassign = function (data) {
  return fetch({
    url: '/employee/assign',
    method: 'post',
    data:data
  })
}
userService.showassignemployee = function (data) {
  return fetch({
    url: '/manager/show',
    method: 'post',
    data:data
  })
}
userService.getAllowanceBillList = function (data) {
  return fetch({
    url: '/allowances/bill/search',
    method: 'post',
    data:data
  })
}
userService.getAllowanceBill = function (data) {
  return fetch({
    url: '/allowances/bill/list',
    method: 'post',
    data:data
  })
}
userService.addAllowanceBill = function (data) {
  return fetch({
    url: '/allowances/bill/create',
    method: 'post',
    data:data
  })
}
userService.salarygenerate = function (data) {
  return fetch({
    url: 'payslip/generate',
    method: 'post',
    data:data
  })
}
userService.deleteAllowanceBill = function (data) {
  return fetch({
    url: 'allowances/bill/delete',
    method: 'post',
    data:data
  })
}
userService.employeetodolist = function (data) {
  return fetch({
    url: '/task/show',
    method: 'post',
    data:data
  })
}
userService.vacancy = function (data) {
  return fetch({
    url: '/job/opening/list',
    method: 'post',
    data:data
  })
}
userService.addvacancy = function (data) {
  return fetch({
    url: 'job/opening/create',
    method: 'post',
    data:data
  })
}
userService.editvacancy = function (data) {
  return fetch({
    url: 'job/opening/update',
    method: 'post',
    data:data
  })
}
userService.deletevacancy = function (data) {
  return fetch({
    url: 'job/opening/delete',
    method: 'post',
    data:data
  })
}
userService.circularlist = function (data) {
  return fetch({
    url: 'circular/list',
    method: 'post',
    data:data
  })
}
userService.addcircular = function (data) {
  return fetch({
    url: 'circular/create',
    method: 'post',
    data:data
  })
}
userService.editcircular = function (data) {
  return fetch({
    url: 'circular/update',
    method: 'post',
    data:data
  })
}
userService.deletecircular = function (data) {
  return fetch({
    url: 'circular/delete',
    method: 'post',
    data:data
  })
}
userService.showcircular = function (data) {
  return fetch({
    url: 'circular/show',
    method: 'post',
    data:data
  })
}
export default userService;
